<template>
  <v-menu offset-y :nudge-width="200">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-on="on" v-bind="attrs" data-testid="app-chooser" class="mr-2">
        <v-icon>mdi-apps</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item :to="{ name: 'Home' }" exact data-testid="app-chooser-dashboard">
        Dashboard
      </v-list-item>

      <v-list-item :to="{ name: 'Routine' }" data-testid="app-chooser-routine">
        Feladataim
      </v-list-item>

      <v-list-item :to="{ name: 'Documents' }" data-testid="app-chooser-documents">
        Dokumentumaim
      </v-list-item>

      <v-list-item :to="{ name: 'Finance' }" data-testid="app-chooser-finance">
        Pénzügyeim
      </v-list-item>

      <!-- <v-btn :to="{ name: 'Testing' }" text>
        Teszteléshez
      </v-btn> -->
    </v-list>
  </v-menu>
</template>

<script>
export default {

};
</script>
