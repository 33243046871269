<template>
  <span>
    <v-menu offset-y :nudge-width="200" v-if="currentWorkspace">
      <template v-slot:activator="{ on }">
        <v-avatar
          color="secondary"
          rounded
          size="48"
          v-on="on"
          class="mr-2"
          style="cursor: pointer;"
          data-testid="workspace-chooser"
        >
          <span class="white--text text-h6">{{ workspaceAvatar(currentWorkspace) }}</span>
        </v-avatar>
      </template>

      <v-list>
        <v-list-item-group color="secondary">
          <v-list-item
            v-for="workspace in workspaces"
            :key="workspace.id"
            :class="{'v-item--active v-list-item--active': workspace.id == currentWorkspace.id}"
            @click="$store.dispatch('workspace/setCurrentWorkspace', workspace)"
          >
            <v-avatar color="secondary" rounded size="36">
              <span class="white--text text-h7">{{ workspaceAvatar(workspace) }}</span>
            </v-avatar>

            <v-list-item-title class="ml-3">{{ workspace.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>

        <v-list-item @click="createWorkspace">
          <v-avatar color="primary" rounded size="36">
            <span class="white--text text-h7">+</span>
          </v-avatar>

          <v-list-item-title class="ml-3">Create new workspace</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <edit-workspace-dialog ref="editWorkspaceDialog" />
  </span>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import EditWorkspaceDialog from './dialog/EditWorkspaceDialog.vue';

export default {
  components: { EditWorkspaceDialog },

  computed: {
    ...mapState('workspace', ['workspaces', 'currentWorkspace']),
  },

  methods: {
    ...mapActions('workspace', ['listWorkspaces', 'save']),

    workspaceAvatar(workspace) {
      if (workspace.name.indexOf(' ') !== -1) {
        const s = workspace.name.split(' ');

        return `${s[0].charAt(0)}${s[1].charAt(0)}`.toUpperCase();
      }

      return workspace.name.length === 1
        ? workspace.name.charAt(0).toUpperCase()
        : `${workspace.name.charAt(0)}${workspace.name.charAt(1)}`.toUpperCase();
    },

    async createWorkspace() {
      try {
        const data = await this.$refs.editWorkspaceDialog.open();
        await this.save(data);
        this.listWorkspaces();
      } catch {
        //
      }
    },
  },
};
</script>
