<template>
  <v-menu offset-y :nudge-width="200">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-on="on" v-bind="attrs" data-testid="main-dropdown-menu">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <slot />

      <v-list-item @click="goToSettings">
        <v-icon class="mr-2">mdi-cog</v-icon>
        Settings
      </v-list-item>

      <v-list-item v-if="$store.state.user.role == 'admin'" :href="adminUrl">
        <v-icon class="mr-2">mdi-cogs</v-icon>
        Admin
      </v-list-item>

      <v-list-item :href="logoutUrl">
        <v-icon class="mr-2">mdi-logout</v-icon>
        Logout
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  computed: {
    logoutUrl() {
      return `${process.env.VUE_APP_BACKEND_HOST}/logout`;
    },

    adminUrl() {
      return process.env.VUE_APP_ADMIN_URL;
    },
  },

  methods: {
    goToSettings() {
      this.$store.commit('setSettingsBackRoute', this.$route);
      this.$router.push({ name: 'SettingsHome' });
    },
  },
};
</script>
