<template>
  <div>
    <v-app-bar
      app
      color="primary"
      dark
      elevation="2"
    >
      <v-row no-gutters>
        <v-col :cols="cols[0]" class="d-flex align-center">
          <workspace-chooser />

          <app-chooser />

          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-col>

        <v-col :cols="cols[1]" class="d-flex align-center">
          <slot name="header" />
        </v-col>

        <v-col :cols="cols[2]" class="d-flex align-center">
          <slot name="menu" />

          <main-dropdown-menu>
            <slot name="vertical_more" />
          </main-dropdown-menu>
        </v-col>
      </v-row>
    </v-app-bar>

    <slot name="content" />
  </div>
</template>

<script>
import AppChooser from '../components/AppChooser.vue';
import MainDropdownMenu from '../components/MainDropdownMenu.vue';
import WorkspaceChooser from '../components/workspace/WorkspaceChooser.vue';

export default {
  components: { WorkspaceChooser, AppChooser, MainDropdownMenu },

  props: {
    title: {
      type: String,
      default: 'Kode-hub',
    },

    cols: {
      type: Array,
      default: () => [4, 4, 4],
    },
  },
};
</script>
